// Step2.js

import React from 'react';


function Step2({onSubmit, general, intro, defs, instructions}) {

  return (
    <div>
      <form onSubmit={onSubmit}>
        <div className='block'>
          <h3 className="title is-3">Supporting Sections</h3>
        </div>
        <div className='block'>
          <label htmlFor="introBody" className="title is-4">Introduction</label>
          <textarea className="textarea" id="introBody" name="introBody" defaultValue={intro} rows="3" cols="50"></textarea>
        </div>
        <div className='block'>
          <label htmlFor="generalBody" className="title is-4">General Objections</label>
          <textarea className="textarea" id="generalBody" name="generalBody" defaultValue={general} rows="15" cols="50"></textarea>
        </div>
        <div className="has-text-right">
          <button className="button is-normal is-success" type="submit">Update Draft</button>
        </div>
      </form>
    </div>
  );
}

export default Step2;