// Step3.js

import React, { useState } from 'react';
import { API_URL } from '../Constants';
import axios from 'axios';



function Step3({ draftId }) {

  const handleDownload = async () => {
    try {
      const response = await axios({
        url: API_URL + 'download-word/'+ draftId,
        method: 'GET',
        responseType: 'blob',
      });
  
      const blob = response.data;
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', 'LexIntel_draft' + draftId + '.docx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    } catch (error) {
      console.error('Download failed:', error);
    }
  };

  return (
    <div>
      <div className='block'><h3 className="title is-3">Review and Finalize</h3></div>
      <div className='block'>Please review the draft and download.</div>
      <button 
        className="button is-success" 
        onClick={handleDownload}
        title={"Click to download"}>
        Download as Word Document
      </button>
    </div>
  );
}

export default Step3;