// src/amplify-confg.js

const awsConfig = {
  Auth: {
    Cognito: {
      userPoolId: "us-east-1_x8GRP09XU",
      userPoolClientId: "7lq6c66o0l77e7fest715caps8",
      identityPoolId: "us-east-1:08434519-1894-4d7b-8701-86b46274ff96",
      loginWith: {
        email: true,
      },
      signUpVerificationMethod: "code",
      userAttributes: {
        email: {
          required: true,
        },
      },
      passwordFormat: {
        minLength: 8,
        requireLowercase: true,
        requireUppercase: true,
        requireNumbers: true,
        requireSpecialCharacters: true,
      },
      mfaConfiguration: "ON", // Enable MFA
      mfaTypes: ["TOTP"], // Define types of MFA
      totpEnabled: true, // Enable Time-based One-Time Password (TOTP)
    },
  },
};

export default awsConfig;
